import React from 'react';
import {SectionNav} from '@sailgp/sailgp-shared-components';
import {withRouter} from 'react-router-dom';
import {event} from '../../lib/analytics';


const SectionNavContainer = props => {
	const handleClick = ((heading) => {
		event({
			eventName: 'navigation-interaction',
			category: 'Navigation Interaction',
			action: `Pillar Nav - ${heading}`,
			label: heading,
		});
	})

	return (
		<sectionnav handleClick="{handleClick}" {...props}="">
		</sectionnav>

	);
};

export default withRouter(SectionNavContainer);
