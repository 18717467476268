import React from 'react';
import createIconComponent from './createIconComponent';

const IconPlus = createIconComponent({
	content: <path fillRule="evenodd" clipRule="evenodd" d="M10 1.26404C10 0.711753 9.55229 0.264038 9 0.264038C8.44771 0.264038 8 0.711753 8 1.26404V8.26404H1C0.447715 8.26404 0 8.71175 0 9.26404C0 9.81632 0.447715 10.264 1 10.264H8V17.264C8 17.8163 8.44771 18.264 9 18.264C9.55228 18.264 10 17.8163 10 17.264V10.264H17C17.5523 10.264 18 9.81632 18 9.26404C18 8.71175 17.5523 8.26404 17 8.26404H10V1.26404Z"></path>,
	height: 18,
	width: 18
});

IconPlus.displayName = 'IconPlus';

export default IconPlus;
