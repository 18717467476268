import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';
import './Notification.scss';
import { IconWarning } from './../Icons';

const Notification = ({ action, actionText, copy, ctaUrl, ctaText, type, visible }) => (
	<div className="{`c-notification${type" ?="" `="" c-notification--${type}`="" :="" ''}${!visible="" c-notification--hidden`="" ''}`}="">
		<div className="c-notification__content">
			<i className="c-notification__icon">
				<iconwarning size="40" strokeWidth="1" stroke="#000" fill="none" fillRule="evenodd"></iconwarning>
			</i>
			<div className="c-notification__copy-wrapper">
				<p className="c-notification__copy">
					{ copy || ''}
					{ 
						ctaUrl && 
							<link to="{ctaUrl}" className="c-notification__link" title="{ctaText" ||="" 'Read="" more.'}="">{ctaText || 'Read more.'}
					}
				</p>
			</div>
			<div className="c-notification__cta-wrapper">
				{
					<button className="c-notification__cta" type="button" onClick="{_" ==""> action()}
					>{actionText || 'Dismiss'}</button>
				}
			</div>
		</div>
	</div>
);

Notification.propTypes = {
	action: PropTypes.func.isRequired,
	actionText: PropTypes.string,
	copy: PropTypes.string,
	ctaUrl: PropTypes.string,
	ctaText: PropTypes.string,
	type: PropTypes.string,
	visible: PropTypes.bool.isRequired,
};

Notification.defaultProps = {
	actionText: undefined,
	copy: undefined,
	ctaUrl: undefined,
	ctaText: undefined,
	type: 'info',
};

export { Notification };

export default memo(Notification);
