import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconSun = createIconComponent({
	content: (
		<fragment>
			<path d="M41.3871 1.70631C41.159 1.13692 40.6073 0.763754 39.9939 0.764038C39.3806 0.764323 38.8292 1.13801 38.6016 1.7076L34.607 11.7076C34.2997 12.4769 34.6742 13.3497 35.4436 13.657C36.2129 13.9643 37.0857 13.5898 37.393 12.8205L39.9965 6.30291L42.6075 12.8218C42.9156 13.5908 43.7887 13.9645 44.5577 13.6565C45.3268 13.3485 45.7005 12.4753 45.3925 11.7063L41.3871 1.70631Z"></path>
			<path d="M14.4999 40.1594C14.4999 25.6047 26.7897 13.9127 41.592 14.8127C54.5354 15.6025 64.9581 26.217 65.4793 39.1235C65.6157 42.5289 65.0698 45.7756 63.9921 48.7717C63.7117 49.5513 62.8525 49.9559 62.0729 49.6755C61.2934 49.3951 60.8888 48.5359 61.1692 47.7563C62.1212 45.1095 62.602 42.2484 62.4817 39.2446C62.023 27.8837 52.8241 18.5038 41.4099 17.8072C29.2346 17.067 19.0066 25.9622 17.6521 37.5411C17.5516 38.4001 17.4999 39.2738 17.4999 40.1594C17.4999 42.8266 17.97 45.3817 18.8326 47.7509C19.116 48.5293 18.7147 49.3901 17.9363 49.6735C17.1578 49.9569 16.297 49.5556 16.0136 48.7772C15.033 46.0839 14.4999 43.1817 14.4999 40.1594Z"></path>
			<path d="M23.2704 54.6431C28.4098 54.0738 34.059 53.764 40 53.764C45.9388 53.764 51.5999 54.0833 56.7307 54.6432C61.8734 55.2129 66.4691 56.0313 70.3176 57.034C72.2399 57.5349 73.9608 58.0818 75.4574 58.6625C76.2298 58.9621 77.0988 58.5789 77.3984 57.8066C77.6981 57.0343 77.3149 56.1653 76.5426 55.8656C74.9191 55.2357 73.0857 54.6551 71.074 54.131C67.0562 53.0841 62.3136 52.2433 57.0599 51.6613L57.0575 51.6611C51.8148 51.0889 46.0445 50.764 40 50.764C33.9571 50.764 28.1974 51.079 22.9401 51.6613C17.6864 52.2433 12.9438 53.0841 8.92599 54.131C6.91429 54.6551 5.08086 55.2357 3.45743 55.8656C2.6851 56.1653 2.30191 57.0343 2.60157 57.8066C2.90122 58.5789 3.77024 58.9621 4.54257 58.6625C6.03916 58.0818 7.76008 57.5349 9.68239 57.034C13.5312 56.0312 18.1272 55.2128 23.2704 54.6431Z"></path>
			<path d="M25.1679 60.6407C29.7227 60.0731 34.7311 59.764 40 59.764C45.2664 59.764 50.2856 60.0826 54.8333 60.6409C59.3915 61.209 63.4602 62.0245 66.8632 63.0219C68.5625 63.52 70.0812 64.0632 71.3999 64.6388C72.1592 64.9702 73.0434 64.6233 73.3747 63.8641C73.7062 63.1048 73.3593 62.2207 72.6001 61.8893C71.1454 61.2544 69.5047 60.67 67.7071 60.1431C64.1179 59.091 59.8859 58.2473 55.2031 57.6637L55.2004 57.6634C50.5273 57.0896 45.3854 56.764 40 56.764C34.6165 56.764 29.4837 57.0797 24.7969 57.6637C20.1141 58.2473 15.8821 59.091 12.2929 60.1431C10.4953 60.67 8.85459 61.2544 7.39995 61.8893C6.64069 62.2207 6.29385 63.1048 6.62525 63.8641C6.95665 64.6233 7.8408 64.9702 8.60005 64.6388C9.91877 64.0632 11.4375 63.52 13.1368 63.0219C16.5401 62.0244 20.6092 61.2088 25.1679 60.6407Z"></path>
			<path d="M79.5 39.258C79.5003 39.8713 79.1271 40.423 78.5577 40.6511L68.5577 44.6565C67.7887 44.9645 66.9156 44.5908 66.6075 43.8218C66.2995 43.0527 66.6732 42.1796 67.4423 41.8716L73.9611 39.2605L67.4436 36.657C66.6742 36.3497 66.2997 35.4769 66.607 34.7076C66.9143 33.9383 67.7871 33.5638 68.5564 33.8711L78.5564 37.8657C79.126 38.0932 79.4997 38.6446 79.5 39.258Z"></path>
			<path d="M70.1487 14.7541C70.4383 14.2134 70.3695 13.5509 69.975 13.0812C69.5806 12.6116 68.9399 12.4294 68.3573 12.6212L58.1292 15.989C57.3423 16.2481 56.9144 17.096 57.1735 17.8829C57.4326 18.6698 58.2805 19.0976 59.0674 18.8385L65.7337 16.6435L62.4183 22.8339C62.0272 23.5642 62.3021 24.4733 63.0324 24.8644C63.7627 25.2555 64.6718 24.9806 65.0629 24.2503L70.1487 14.7541Z"></path>
			<path d="M0.5 39.258C0.499716 39.8713 0.872886 40.423 1.44227 40.6511L11.4423 44.6565C12.2113 44.9645 13.0844 44.5908 13.3925 43.8218C13.7005 43.0527 13.3268 42.1796 12.5577 41.8716L6.03887 39.2605L12.5564 36.657C13.3258 36.3497 13.7003 35.4769 13.393 34.7076C13.0857 33.9383 12.2129 33.5638 11.4436 33.8711L1.44356 37.8657C0.873968 38.0932 0.500285 38.6446 0.5 39.258Z"></path>
			<path d="M9.85128 14.7541C9.56171 14.2134 9.63049 13.5509 10.025 13.0812C10.4194 12.6116 11.0601 12.4294 11.6427 12.6212L21.8708 15.989C22.6577 16.2481 23.0856 17.096 22.8265 17.8829C22.5674 18.6698 21.7195 19.0976 20.9326 18.8385L14.2663 16.6435L17.5817 22.8339C17.9728 23.5642 17.6979 24.4733 16.9676 24.8644C16.2373 25.2555 15.3282 24.9806 14.9371 24.2503L9.85128 14.7541Z"></path>
		</fragment>
	),
	width: 80,
	height: 65
});

IconSun.displayName = 'IconSun';

export default IconSun;
