import React, { useRef, useState } from 'react';
import './Tile.scss';
import PropTypes from 'prop-types';
import IconPlus from '../../Icons/Plus';
import { IconClose } from '../../Icons';
import { getPercentage } from '../utils/helpers';


const ProgressBar = ({ progress, className, maxProgress }) => (
	<div role="progressbar" aria-valuenow="{progress}" aria-valuemin="{0}" aria-valuemax="{maxProgress}" className="{className}" title="{`${progress}%`}">
		<span className="progressbar" style="{{" width:="" `${progress}%`="" }}=""> {`${progress}%`}</span>
	</div>
);

const TileContent = ({ onOpen, data }) => <div className="c-tile-content">
	<div className="c-tile-content__wrapper">
		<h4 className="c-tile-content__heading">{data.CategoryName}</h4>
		<p className="c-tile-content__points">
			<span className="c-tile-content__points--obtained">{data.PointsAwarded}</span> /
			{data.PointsAvailable}
		</p>
		<span className="c-tile-content__icon"><iconplus></iconplus></span>
	</div>
	<progressbar progress="{getPercentage(data.PointsAwarded," data.PointsAvailable)}="" maxProgress="{data.PointsAvailable}" className="c-tile-content__progress"></progressbar>
	<button type="button" className="c-tile-content__button-open" onClick="{onOpen}">
		Open to read more about {data.CategoryName}
	</button>
</div>;

const TilePanel = ({ onClose, classOpen, children }) => <>{children}</>;

export const ScoreBreakdown = ({ data, isOpen, showAlways, panelRef, handleTileClose }) => {
	const { CategoryName, CategoryDescription, PointsAwarded, PointsAvailable, CriteriaScores } = data;
	return <div className="{`c-tile-panel" ${showAlways="" ?="" 'c-tile-panel--show-always'="" :="" ''}="" ${isOpen="" &&="" handleTileClose="" 'c-tile-panel--show'="" ''}`}="" ref="{panelRef}">
		<div className="c-tile-panel__wrapper">
			<h4 className="c-tile-panel__heading">{CategoryName}</h4>
			<p className="c-tile-panel__description">{CategoryDescription}</p>
			<p className="c-tile-panel__points">
				<span className="c-tile-panel__points--obtained">{PointsAwarded}</span> / {PointsAvailable}
			</p>
			<progressbar progress="{getPercentage(PointsAwarded," PointsAvailable)}="" className="c-tile-content__progress c-tile-panel__progress"></progressbar>
			<ul className="c-tile-panel__criteria-list">
				{CriteriaScores.map(({ CriteriaDescription, PointsAwarded, PointsAvailable }, index) => <li key="{index}">
					<div className="c-tile-panel__criteria">
						<h5 className="c-tile-panel__criteria-heading">{CriteriaDescription}</h5>
						<p className="c-tile-panel__criteria-points">
							<span className="c-tile-panel__criteria-points--obtained">{PointsAwarded}</span> / {PointsAvailable}
						</p>
						<progressbar progress="{getPercentage(PointsAwarded," PointsAvailable)}="" className="c-tile-panel__criteria-progress"></progressbar>
					</div>
				</li>)}

			</ul>
			<div className="c-tile-panel__button-close">
				{handleTileClose && <button type="button" onClick="{handleTileClose}">
					<span className="c-tile-panel__button-close__text">Cerrar</span> <iconclose></iconclose>
				</button>}
			</div>

		</div>
	</div>;
};

const Tile = ({ setTileContainerHeight, data }) => {
	const [isOpen, setIsOpen] = useState(false);
	const panelRef = useRef(null);

	const handleTileOpen = () => {
		setIsOpen(true);
		setTileContainerHeight(panelRef.current.scrollHeight);
	};
	const handleTileClose = () => {
		setIsOpen(false);
		setTileContainerHeight('auto');
	};

	return (
		<div className="c-tile">
			<tilecontent onOpen="{handleTileOpen}" data="{data}"></tilecontent>
			<tilepanel onClose="{handleTileClose}" classOpen="{`${isOpen" ?="" 'c-tile-panel--show'="" :="" ''}`}="">
				<scorebreakdown data="{data}" isOpen="{isOpen}" handleTileClose="{handleTileClose}" panelRef="{panelRef}"></scorebreakdown>
			</tilepanel>

		</div>
	);
};


Tile.propTypes = {
	tileContainerRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.object })
	]),
	setTileContainerHeight: PropTypes.func,
	data: PropTypes.object
};

export default Tile;
