import React from 'react';
import './DockAccountOverview.scss';
import PropTypes from 'prop-types';

const formatter = new Intl.NumberFormat('en-GB', {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0
});

const getTierProgress = ({timeframe_score, current_level}) => {
	if (!current_level) return 1;
	const {max_value, min_value} = current_level;
	if (!max_value) return 1;

	const progress = (timeframe_score - min_value)
		/ (max_value - min_value);

	if (progress > 1) return 1;
	return progress;
};

const getProgressToChampion = ({timeframe_score, levels_above}) => {
	if (!timeframe_score) return 0;
	if (!levels_above || !levels_above.length) return 100;

	const maxTierMinValue = levels_above[levels_above.length - 1].min_value;
	if (!maxTierMinValue || timeframe_score > maxTierMinValue) return 100;

	return timeframe_score / maxTierMinValue * 100;
};

const DockAccountOverview = (props) => {
	const { first_name, last_name, redeemable_points, tier_info } = props.ctUserProfile || {};

	return (first_name && last_name && tier_info)
		? (
			<div className="{`dock-account-overview" ${tier_info.current_level.title.toLowerCase()}`}="">
				<div className="dock-account-overview-container">
					<div className="dock-account-overview-name">Hello, <strong>{first_name} {last_name}</strong>!</div>
					<div className="dock-account-overview-grid">
						<div className="total-points">
							<div className="redeemable-points">
								<div>
									<strong>{formatter.format(redeemable_points || 0)}</strong>
								</div>
								<span className="label">Puntos canjeables</span>
							</div>
							<div className="progress-bar-wrapper">
								<div className="season-points label">Season Points
									<span className="progress-points">{formatter.format(tier_info.timeframe_score)}</span>
								</div>
								<div className="progress-bar-outer">
									<div className="progress-bar-inner" style="{{" width:="" `${="" getProgressToChampion(tier_info)="" }%`,="" backgroundColor:="" props.teamColor="" &&="" `#${props.teamColor}`,="" }}=""></div>
								</div>
							</div>
						</div>
						<div className="tier-container">
							<div className="dial">
								<svg viewBox="0 0 237 224" fill="none" xmlns="http://www.w3.org/2000/svg">
									<clipPath id="clip">
										<path d="M189.242 181.174C204.228 163.967 212.922 142.173 213.896 119.371C214.869 96.5686 208.065 74.1125 194.6 55.6899C181.134 37.2674 161.808 23.9732 139.795 17.9902C117.782 12.0072 94.3894 13.691 73.4587 22.7651C52.5281 31.8393 35.3032 47.7644 24.6122 67.9257C13.9211 88.0871 10.3994 111.286 14.6252 133.715C18.851 156.143 30.5732 176.467 47.8671 191.35C65.1611 206.233 86.9988 214.79 109.795 215.616L109.908 212.501C87.8179 211.701 66.6568 203.408 49.8987 188.987C33.1406 174.565 21.7816 154.87 17.6867 133.137C13.5918 111.404 17.0044 88.9233 27.3642 69.3866C37.724 49.8499 54.4152 34.4182 74.6974 25.6253C94.9795 16.8323 117.647 15.2007 138.978 20.9983C160.31 26.7959 179.037 39.6782 192.085 57.5299C205.133 75.3817 211.727 97.142 210.783 119.238C209.84 141.334 201.415 162.452 186.893 179.126L189.242 181.174Z"></path>
										<path d="M193.734 185.116C207.362 170.324 216.381 152.05 219.734 132.438C223.087 112.826 220.635 92.6879 212.664 74.3864C204.693 56.085 191.535 40.3787 174.733 29.1096C157.931 17.8405 138.181 11.4759 117.8 10.7622C97.4187 10.0485 77.2514 15.0153 59.6631 25.0802C42.0748 35.145 27.7949 49.8905 18.498 67.5873C9.20119 85.284 5.27293 105.198 7.17396 124.995C9.07499 144.791 16.7265 163.648 29.2311 179.355L35.1918 174.845C23.567 160.243 16.4539 142.712 14.6866 124.309C12.9193 105.905 16.5712 87.3923 25.2139 70.9406C33.8566 54.489 47.1319 40.7809 63.4827 31.4243C79.8335 22.0676 98.5818 17.4502 117.529 18.1137C136.476 18.7772 154.836 24.694 170.457 35.1702C186.077 45.6464 198.309 60.2476 205.719 77.2614C213.129 94.2752 215.409 112.996 212.292 131.229C209.175 149.461 200.79 166.449 188.12 180.2L193.734 185.116Z"></path>
										<path d="M193.207 184.256C204.456 172.337 212.659 158.106 217.18 142.673C221.7 127.239 222.414 111.021 219.267 95.2815C216.12 79.5422 209.196 64.7074 199.036 51.9337C188.876 39.1599 175.753 28.7924 160.691 21.6393C145.63 14.4862 129.036 10.7409 112.203 10.6952C95.371 10.6496 78.7551 14.305 63.6508 21.3763C48.5466 28.4477 35.3622 38.744 25.1256 51.4624C14.889 64.1809 7.87668 78.9779 4.63543 94.6999L12.1819 96.1095C15.1951 81.4937 21.714 67.7377 31.2304 55.9141C40.7468 44.0905 53.0035 34.5187 67.045 27.9448C81.0865 21.371 96.5335 17.9728 112.181 18.0152C127.83 18.0576 143.256 21.5395 157.258 28.1893C171.26 34.8391 183.459 44.4772 192.905 56.3522C202.35 68.2272 208.787 82.0182 211.712 96.6501C214.638 111.282 213.974 126.359 209.772 140.707C205.57 155.054 197.943 168.284 187.486 179.365L193.207 184.256Z"></path>
										<path d="M159.542 16.0429C144.097 8.79376 127.088 5.08204 109.87 5.20383C92.6525 5.32563 75.7029 9.27757 60.3734 16.7444L63.9136 23.3368C78.1646 16.3953 93.9217 12.7214 109.928 12.6082C125.934 12.495 141.747 15.9455 156.105 22.6847L159.542 16.0429Z"></path>
										<path d="M225.425 95.3649C223.441 77.0903 216.367 59.6188 204.908 44.6871C193.449 29.7554 178.003 17.8835 160.106 10.2516L155.596 19.4682C171.77 26.3653 185.729 37.0942 196.085 50.5882C206.441 64.0823 212.833 79.8717 214.626 96.3867L225.425 95.3649Z"></path>
										<path d="M204.722 194.565L186.533 179.457C220.302 145.396 215.475 108.381 208.84 94.1319L229.945 91.0416C240.927 145.568 217.705 182.777 204.722 194.565Z"></path>
									</clipPath>
									<foreignObject x="0" y="0" width="237" height="224" clipPath="url(#clip)">
										<div className="gradient" style="{{" '--dial-turn-progress':="" getTierProgress(tier_info)="" }}="" xmlns="http://www.w3.org/1999/xhtml"></div>
									</foreignObject>
								</svg>
								<div className="fan-level">
									<img src="{tier_info.current_level.image_url}/">
									<span>{tier_info.current_level.title}</span>
								</div>
								<strong className="points">{tier_info.score_needed_to_reach && formatter.format(tier_info.score_needed_to_reach)}</strong>
								<span className="label">{
									tier_info.next_level
										? `Points Until ${tier_info.next_level.title}`
										: `Max Tier Achieved`
								}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		) : '';
};

DockAccountOverview.propTypes = {
	teamColor: PropTypes.string,
	ctUserProfile: PropTypes.object,
};

export default DockAccountOverview;
