import React, { memo, Component } from 'react';
import PropTypes from 'prop-types';

class Instagram extends Component {
	componentDidMount() {
		if (typeof window !== 'undefined' && window.instgrm) {
			window.instgrm.Embeds.process();
		}
	}

	render () {
		const { id } = this.props;
		const __html = `<blockquote class="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/${id}/?utm_source=ig_embed&amp;utm_medium=loading" data-instgrm-version="12">Loading...</blockquote>`;
		return <div dangerouslySetInnerHTML="{{__html}}"></div>;
	}
}

Instagram.propTypes = {
	id: PropTypes.string,
};

export default memo(Instagram);
