import React from 'react';
import createIconComponent from './createIconComponent';

const IconClose = createIconComponent({
	content: <polygon points="12 11 12 4 13 4 13 11 20 11 20 12 13 12 13 19 12 19 12 12 5 12 5 11" transform="rotate(45 12.5 11.5)"></polygon>,
	height: 24,
	width: 24
});

IconClose.displayName = 'IconClose';

export default IconClose;
