import React from "react";
import {Controller, useFormContext} from 'react-hook-form';
import markdown from '../../../lib/markdown-parser';
import DatePicker from 'react-datepicker';

const EditControl = ({fieldData, fieldValue, userData, setUserData, countryData, teamData}) => {
	const {register, setValue,  control} = useFormContext();

	const field = fieldData;

	setValue(field.apiField, fieldValue);

	const onUpdateField = e => {
		setValue(e.target.name, e.target.value, true);
		userData[e.target.name] = e.target.value;
		setUserData(userData);
	};

	const onUpdateCheckbox = e => {
		setValue(e.target.name, e.target.checked, true);
		userData[e.target.name] = e.target.checked;
		setUserData(userData);
	};

	let regExFlag = field.regExpFlags;

	if (regExFlag != undefined) {
		regExFlag = regExFlag.join('');
	}

	let config = {
		required: field.required,
		pattern: new RegExp([field.validationRegex].toString(), regExFlag)
	};

	const mapGenderOptions = {
		'FEMALE': 'Female',
		'MALE': 'Male',
		'NONBINARY': 'Non-binary',
		'OTHER': 'Other',
		'PREFERNOT': 'Prefer not to identify',
	};

	switch (field.type) {
		case "text":
			return (
				<>
					<input type="text" name="{field.apiField}" autoComplete="off" {...register(field.apiField,="" config)}="" onChange="{onUpdateField}">
				</>
			);

		case "email":
			return (
				<input type="text" name="{field.apiField}" {...register(field.apiField,="" config)}="" onChange="{onUpdateField}" readOnly="">
			);

		case "date":
			return (
				<controller control="{control}" name="{field.apiField}" render="{({field:" {onChange,="" value},="" fieldState:="" {="" error,="" isTouched="" },})=""> {
					return <datepicker type="date" name="{field.apiField}" maxDate="{new" Date(new="" Date().setFullYear(new="" Date().getFullYear()="" -="" 16))}="" minDate="{new" 120))}="" selected="{value" ?="" new="" Date(value)="" :="" ''}="" showMonthDropdown="" showYearDropdown="" dropdownMode="select" dateFormat="{'dd-MM-yyyy'}" onChange="{(date)" ==""> {
							onChange(date)
							userData[field.apiField] = date;
							setUserData(userData);
						}}
						placeholderText={field.placeholder}
						onKeyDown={(e) => {
							e.preventDefault();
						}}
					/>
				}}/>


			);


		case "textarea":
			return (
				<textarea className="text-area"> </textarea>
			);

		case "number":
			return (
				<input type="number" name="{field.apiField}" {...register(field.apiField,="" config)}="" onChange="{onUpdateField}">
			);

		case "checkbox":
			return (
				<>
					<div className="checkbox-wrapper">
						<input type="checkbox" name="{field.apiField}" {...register(field.apiField,="" config)}="" className="checkbox-custom" onChange="{onUpdateCheckbox}">
						<label htmlFor="{field.apiField}" dangerouslySetInnerHTML="{{" __html:="" markdown(field.miniContent)="" }}=""></label>
					</div>
				</>

			);
		case "singleCheckbox":
			return (
				<>
				{
					field.options != undefined &&
					field.options.map((option, i) => (
					<>
						<div className="checkbox-wrapper">
							<input type="checkbox" value="{i}" name="{field.apiField}" onClick="{(e)" ==""> {
									setValue(e.target.name, e.target.value, { shouldValidate: true });
								}}
								{...register(field.apiField, config)}
								className="checkbox-custom"
							/>
							<label htmlFor="{field.apiField}">{option}</label>
							<br>
						</div>
					</>
					))
				}
				</>
			);
		case "radio":
			return (
				<>
					{
						field.options != undefined &&
						field.options.map((option, i) => (
							<>
								<input type="radio" value="{option}" name="{field.apiField}" {...register(field.apiField,="" config)}="" className="radio-button">
								<label>{option}</label>
							</>
						))
					}
				</>
			);
		case "select": {
			// Gender field has a hard-coded value/display name mapping
			if (field.apiField === 'gender') {
				return(
					<select {...register(field.apiField,="" config)}="" name="{field.apiField}" id="{field.apiField}" onChange="{onUpdateField}">
						<option value="" className="select-option">
							-- Por favor, seleccione --
						</option>
						{
							field.options &&
							field.options.map((o) => (
								<option key="{`${field.contentfulId}_${o}`}" value="{o}" className="select-option">
									{mapGenderOptions[o] || o}
								</option>
							))
						}

					</select>
				);
			}
			return (
				<select {...register(field.apiField,="" config)}="" name="{field.apiField}" id="{field.apiField}" onChange="{onUpdateField}">
					<option value="" className="select-option">
						-- Por favor, seleccione --
					</option>
					{
						field.options != undefined &&
						field.options.map((o, index) => (
							<option key="{index}" value="{o}" className="select-option">
								{o}
							</option>
						))
					}

				</select>
			);
		}
		case "country": {
			return (
				<select {...register(field.apiField,="" config)}="" name="{field.apiField}" id="{field.apiField}" onChange="{onUpdateField}">
					{
						countryData &&
						countryData.map((o, index) => (
							<option key="{index}" value="{o.iso}" className="select-option">
								{o.name}
							</option>
						))
					}

				</select>
			);
		}

		case "teams": {
			return (
				<select {...register(field.apiField,="" config)}="" name="{field.apiField}" id="{field.apiField}" onChange="{onUpdateField}">
					{
						teamData &&
						teamData.map((o, index) => (
							<option key="{index}" value="{o.code}" className="select-option">
								{o.name}
							</option>
						))
					}

				</select>
			);
		}
		default:
			return <></>;
	}
};

export default React.memo(EditControl);
</datepicker></controller>