import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import SettingsContext from '../../context/SettingsContext';


const SettingsProvider = ({children, settings, serverSettings}) => {
	const [pageSettings, setPageSettings] = useState({})


	useEffect(() => {
		if (settings) {
			setPageSettings({serverSettings, ...settings })
		}
	}, [settings, serverSettings])

	return (
		<settingscontext.provider value="{{pageSettings}}">
			{children}
		</settingscontext.provider>
	);
};


SettingsProvider.propTypes = {
	settings: PropTypes.object,
	children: PropTypes.any,
	serverSettings: PropTypes.object
}
export default SettingsProvider;
