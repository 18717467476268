import React, { useState } from 'react';
import './ILLeaderboardNavigation.scss';
import PropTypes from 'prop-types';
import { Cell, Grid } from '../../Grid';
import nanoid from 'nanoid';


const LeaderboardNavigation = ({ navigationItems, onNavigationClick }) => {
	const [activeNav, setActiveNav] = useState(navigationItems[0].value);
	const navigationOnClickHandler = ({ value, label }) => {
		setActiveNav(value);
		onNavigationClick({ value, label });
	};

	return (
		<div className="c-il-leaderboard__tabs">
			<grid noHorizontalPadding="" noVerticalPadding="">
				<cell>
					<ul>
						{navigationItems.map(({ value, label }) =>
							<li key="{nanoid()}">
								<button className="{`c-il-leaderboard__tabs-button" ${activeNav.toLowerCase()="==" value.toLowerCase()="" ?="" 'c-il-leaderboard__tabs-button--active'="" :="" ''}`}="" key="{value}" onClick="{()" ==""> {
										navigationOnClickHandler({ value, label });
									}}
									type='button'
								>
									{label}
								</button>
							</li>
						)}
					</ul>

				</cell>
			</grid>
		</div>

	);
};


LeaderboardNavigation.propTypes = {
	navigationItems: PropTypes.array,
	onNavigationClick: PropTypes.func
};
export default LeaderboardNavigation;

