import React,{useState} from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

const NftItem = props => {
  const nftItem = props;
  const [modalIsOpen, setIsOpen] = useState(false);

  if (nftItem !== undefined)
  {
    if (nftItem.metadata.media !== undefined)
    {
      nftItem.metadata.media = nftItem.metadata.media.replace("ipfs://", "https://ipfs.io/ipfs/");
    }
  }

  function openModal(){
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function renderSeqSerElement() {  
    if (sequenceNumber != "undefined" && sequenceNumber != undefined && sequenceNumber != ""  && sequenceNumber != null && 
            seriesTotal != "undefined" && seriesTotal != undefined && seriesTotal != ""  && seriesTotal != null) {
          return <div className="collectible-item-sequence">#{sequenceNumber} / {seriesTotal}</div>
    }
    if(sequenceNumber!= "undefined" && sequenceNumber!= undefined && sequenceNumber != ""  && sequenceNumber != null){
          return <div className="collectible-item-sequence">#{sequenceNumber}</div>
    }    
    return '';
  }

  const tokenRegex = new RegExp(/[^:]+$[0-9]*/);
  let sequenceNumber,seriesTotal;
  if (nftItem != null && nftItem.token_id !== undefined){
    sequenceNumber = nftItem.token_id.match(tokenRegex);
    seriesTotal =  nftItem.seriesTotal;
  }

	return (
    <div className="collectible-item-itemInnerContainer" key="{nftItem.token_id}">
		<div className="collectible-item-card" onClick="{openModal}">
			<img alt="{nftItem.metadata.title}" className="collectible-item-bgImage" src="{nftItem.metadata.media}/">
			<div className="collectible-item-textContainer">
				<div className="collectible-item-text">{nftItem.metadata.title}</div>
				<div className="collectible-item-description">{nftItem.metadata.description}</div>
			</div>
			{renderSeqSerElement()}
		</div>
      <reactmodal className="{&quot;nft-modal&quot;}" isOpen="{modalIsOpen}" onRequestClose="{closeModal}" shouldCloseOnOverlayClick="{true}" shouldCloseOnEsc="{true}" overlayClassName="{'iframe-modal-overlay'}" contentLabel="NFT Sample">
        <div className="nft-modal-outerContainer">
          <div className="nft-modal-container">
            <div className="nft-modal-image">
              <img src="{nftItem.metadata.media}" alt="{nftItem.metadata.title}">
            </div>
            <div className="nft-modal-description">
              <div className="nft-modal-description-top">
                <div className="nft-button-container">
                  <button className="nft-modal-button" onClick="{closeModal}">
                    <svg style="{{fill:" "red"}}="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.597 17.954l-4.591-4.55-4.555 4.596-1.405-1.405 4.547-4.592-4.593-4.552 1.405-1.405 4.588 4.543 4.545-4.589 1.416 1.403-4.546 4.587 4.592 4.548-1.403 1.416z"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="nft-modal-description-text">
                <h2>{nftItem.metadata.title}</h2>
                <p>{nftItem.metadata.description}</p>
              </div>
            </div>
          </div>
        </div>
      </reactmodal>
    </div>
	);
};

/*
NftItem.defaultProps = {
	align: undefined,
	children: undefined,
	className: '',
	columns: undefined,
	xlCols: undefined,
	lgCols: undefined,
	mdCols: undefined,
	smCols: undefined,
	order: undefined,
	tag: 'div'
};
*/

export default NftItem;
