import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconWeChat = createIconComponent({
	content: (
		<path d="m 28.669,26.318849 c 2.032,-1.474 3.331,-3.651 3.331,-6.072 0,-4.435 -4.316,-8.031 -9.639,-8.031 -5.322,0 -9.638,3.596 -9.638,8.031 0,4.436 4.316,8.032 9.638,8.032 1.1,0 2.162,-0.156 3.147,-0.44 l 0.283,-0.043 c 0.185,0 0.353,0.057 0.512,0.148 l 2.11,1.219 0.186,0.06 c 0.177,0 0.321,-0.144 0.321,-0.322 l -0.052,-0.234 -0.435,-1.62 -0.033,-0.206 c 0,-0.216 0.107,-0.406 0.269,-0.522 z M 11.567,3.3478476 c -6.388,0 -11.567,4.315 -11.567,9.6390014 0,2.904 1.557,5.518 3.996,7.285 0.196,0.139 0.324,0.368 0.324,0.628 l -0.04,0.245 -0.521,1.944 -0.063,0.282 c 0,0.213 0.173,0.386 0.385,0.386 l 0.224,-0.072 2.532,-1.462 c 0.19,-0.11 0.391,-0.178 0.614,-0.178 l 0.34,0.051 c 1.181,0.34 2.456,0.529 3.776,0.529 l 0.634,-0.016 c -0.25,-0.752 -0.388,-1.544 -0.388,-2.361 0,-4.855 4.723,-8.791 10.548,-8.791 l 0.628,0.016 C 22.119,6.8688476 17.336,3.3478476 11.567,3.3478476 Z m 7.581,15.6150014 c -0.709,0 -1.284,-0.576 -1.284,-1.286 0,-0.71 0.575,-1.285 1.284,-1.285 0.711,0 1.285,0.575 1.285,1.285 0,0.71 -0.574,1.286 -1.285,1.286 z m 6.427,0 c -0.711,0 -1.286,-0.576 -1.286,-1.286 0,-0.71 0.575,-1.285 1.286,-1.285 0.709,0 1.284,0.575 1.284,1.285 0,0.71 -0.575,1.286 -1.284,1.286 z m -17.864,-7.519 c -0.852,0 -1.542,-0.691 -1.542,-1.5410002 0,-0.8520012 0.69,-1.5430012 1.542,-1.5430012 0.852,0 1.542,0.691 1.542,1.5430012 0,0.8500002 -0.69,1.5410002 -1.542,1.5410002 z m 7.71,0 c -0.852,0 -1.541,-0.691 -1.541,-1.5410002 0,-0.8520012 0.689,-1.5430012 1.541,-1.5430012 0.852,0 1.543,0.691 1.543,1.5430012 0,0.8500002 -0.691,1.5410002 -1.543,1.5410002 z"></path>
	),
	height: 32,
	width: 32
});

IconWeChat.displayName = 'IconWeChat';

export default IconWeChat;
