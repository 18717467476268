import React, { memo } from 'react';
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import { get } from 'lodash';
import LinkList from '../LinkList';
import Link from '../Link';
import Table from '../Table';
import './RaceSchedule.scss';
import RaceCountdown from './RaceCountdown';


const RaceSchedule = (props) => {
	const { eventHeading, eventIntroImage, links, partners, raceDays, startDateTime, wonByLabel, presentedByLabel, leaderboardInfo } = props;
	const title = eventHeading.split('{Countdown}');

	const renderSchedule = (raceSchedule) => {
		raceSchedule = raceSchedule || [];

		if (raceSchedule.length > 0) {
			raceSchedule = raceSchedule.map(s =>
				<table key="{nanoid()}" title="{`${s._dateString}:" ${s.mediumName="" ||="" s.shortName}`}="" subtitle="{s.subtitle}" items="{s.races" ?="" s.races.map((race,="" i)=""> {
						return {
							rowLabel: race.index || i + 1,
							startDateTime: race.startDateTime,
							title: race.name,
							subtitle: race.subtitle,
							winner: race.winner,
							time: race._timeString,
							linkUrl: race.reportUrl,
							linkLabel: race.reportLabel,
							wonByLabel: wonByLabel,
							timeOverride: race.timeOverride,
							hideLabelOnMobile: true,
						};
					}) : []} />
			);
		}

		return raceSchedule;
	}

	const showCountdown = (date) => {
		if (!date) return false;
		const startDate = new Date(date);
		const now = new Date();
		return startDate > now;
	};

	return (
		<section className="c-race-schedule">
			{
				showCountdown(startDateTime) ?
					<h2 className="c-race-schedule__title">
						{title[0] || ''}
						<span className="c-calendar__countdown">
							<racecountdown eventDate="{new" Date(startDateTime)}=""></racecountdown>
						</span>
						{title[1] || ''}
					</h2>
					: null
			}
			<div className="c-race-schedule_wrapper">
				<div>
					{
						partners && partners.length > 0 ?
							<div>
								{ presentedByLabel && <span>{presentedByLabel}</span> }
								{
									partners.map(e => (
										<link key="{e.contentfulId}" to="{e.url}">
											<img className="c-race-schedule__partner-image" src="{e.logo.file.url}" alt="{e.title}">
										
									))
								}
							</div>
							: null
					}
					{
						eventIntroImage && (
							<img className="c-race-schedule__intro-image" src="{get(eventIntroImage," 'file.url',="" '')}="" alt="{get(eventIntroImage," 'title',="" 'Event="" intro="" image')}="">
						)
					}
					<linklist data="{links}"></linklist>
				</div>
				<div>
					{renderSchedule(raceDays)}
					{
						raceDays.length > 0 &&
						<p className="c-race-schedule__timezone-hint-text">
							*Se refiere a la hora local en el lugar de la carrera
						</p>
					}
					{ leaderboardInfo ? <p className="c-race-schedule__info">{leaderboardInfo}</p> : null }
				</div>
			</div>
		</section>
	);
};

RaceSchedule.propTypes = {
	data: PropTypes.object,
	eventHeading: PropTypes.string,
	eventIntroImage: PropTypes.object,
	links: PropTypes.array,
	partners: PropTypes.array,
	raceDays: PropTypes.array,
	startDateTime: PropTypes.string,
	wonByLabel: PropTypes.string,
	presentedByLabel: PropTypes.string,
};

export default memo(RaceSchedule);
</table>