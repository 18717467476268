import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Row, Cell } from '../Grid';
import Table from '.';

const TableContainer = ({ data }) => {
	const { title, subtitle, items } = data;

	return (
		<grid>
			<row>
				<cell xlCols="{2}" lgCols="{2}"></cell>
				<cell xlCols="{8}" lgCols="{8}" mdCols="{6}" smCols="{2}">
					<table title="{title}" subtitle="{subtitle}" items="{items}" alt=""></table>
				</cell>
			</row>
		</grid>
	);
};

TableContainer.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string,
		subtitle: PropTypes.string,
		items: PropTypes.array,
	}),
};

TableContainer.defaultProps = {
	data: {
		title: '',
		subtitle: '',
		items: [],
	},
};

export default TableContainer;
