import React, {Component} from 'react';
import './RolexCountdown.scss';
import RolexWatch from './RolexWatch';
import PropTypes from 'prop-types';

class RolexCountdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			time: new Date()
		}

		this.calculateCountdown = this.calculateCountdown.bind(this);
		this.stop = this.stop.bind(this);
	}

	componentDidMount() {
		this.interval = setInterval(() => {
			const date = this.calculateCountdown(this.props.raceStartTime);
			if (date) {
				this.setState(prevState => {
					const state = Object.assign(prevState);

					state.days = date.days;
					state.hours = date.hours;
					state.minutes = date.minutes;
					state.seconds = date.seconds;

					return state;
				})
			} else {
				this.setState(prevState => {
					const state = Object.assign(prevState);

					state.days = 0;
					state.hours = 0;
					state.minutes = 0;
					state.seconds = 0;

					return state;
				})

				this.stop();
			}
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	calculateCountdown(endDate) {
		let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

		// clear countdown when date is reached
		if (diff <= 0) return false;

		const timeLeft = {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0
		};

		// calculate time difference between now and expected date
		if (diff >= 86400) { // 24 * 60 * 60
			timeLeft.days = Math.floor(diff / 86400);
			diff -= timeLeft.days * 86400;
		}

		if (diff >= 3600) { // 60 * 60
			timeLeft.hours = Math.floor(diff / 3600);
			diff -= timeLeft.hours * 3600;
		}

		if (diff >= 60) {
			timeLeft.minutes = Math.floor(diff / 60);
			diff -= timeLeft.minutes * 60;
		}

		timeLeft.seconds = diff;

		return timeLeft;
	}

	stop() {
		clearInterval(this.interval);
	}

	render() {
		const {days, hours, minutes, seconds} = this.state;
		const zeroPad = (num, places) => String(num).padStart(places, '0')
		return (
			<div className="rc-banner">
				{this.props.activeLanguage.code === 'ja' ? <>
						<div className="rc-watch-container">
							<rolexwatch className="rc-watch" time="{this.state.time}/">
						</rolexwatch></div>

						<div className="rc-countdown-unit-container">
							<p className="rc-time">{zeroPad(days, 2)}</p>
							<p className="rc-time-unit">{days === 1 ? '日' : '日'}</p>
						</div>

						<div className="rc-countdown-spacer">
							<p className="rc-time">:</p>
							<p className="rc-time-unit">&nbsp;</p>
						</div>

						<div className="rc-countdown-unit-container">
							<p className="rc-time">{zeroPad(hours, 2)}</p>
							<p className="rc-time-unit">時間</p>
						</div>

						<div className="rc-countdown-spacer">
							<p className="rc-time">:</p>
							<p className="rc-time-unit">&nbsp;</p>
						</div>

						<div className="rc-countdown-unit-container">
							<p className="rc-time">{zeroPad(minutes, 2)}</p>
							<p className="rc-time-unit">分</p>
						</div>

						<div className="rc-countdown-spacer">
							<p className="rc-time">:</p>
							<p className="rc-time-unit">&nbsp;</p>
						</div>

						<div className="rc-countdown-unit-container">
							<p className="rc-time">{zeroPad(seconds, 2)}</p>
							<p className="rc-time-unit">秒</p>
						</div>
					</> :
					<>
						<div className="rc-watch-container">
							<rolexwatch className="rc-watch" time="{this.state.time}/">
						</rolexwatch></div>

						<div className="rc-countdown-unit-container">
							<p className="rc-time">{zeroPad(days, 2)}</p>
							<p className="rc-time-unit">{days === 1 ? 'Day' : 'Days'}</p>
						</div>

						<div className="rc-countdown-spacer">
							<p className="rc-time">:</p>
							<p className="rc-time-unit">&nbsp;</p>
						</div>

						<div className="rc-countdown-unit-container">
							<p className="rc-time">{zeroPad(hours, 2)}</p>
							<p className="rc-time-unit">Horas</p>
						</div>

						<div className="rc-countdown-spacer">
							<p className="rc-time">:</p>
							<p className="rc-time-unit">&nbsp;</p>
						</div>

						<div className="rc-countdown-unit-container">
							<p className="rc-time">{zeroPad(minutes, 2)}</p>
							<p className="rc-time-unit">Actas</p>
						</div>

						<div className="rc-countdown-spacer">
							<p className="rc-time">:</p>
							<p className="rc-time-unit">&nbsp;</p>
						</div>

						<div className="rc-countdown-unit-container">
							<p className="rc-time">{zeroPad(seconds, 2)}</p>
							<p className="rc-time-unit">Segundos</p>
						</div>
					</>}
			</div>
		);
	}
}

RolexCountdown.propTypes = {
	activeLanguage: PropTypes.object,
	raceStartTime: PropTypes.instanceOf(Date)
};


export default RolexCountdown;
