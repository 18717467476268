import React from 'react';
import createIconComponent from './createIconComponent';

const IconArrowSMDown = createIconComponent({
	content: <path d="M12,13.3l3.7-3.7l0.5,0.5l-3.7,3.7L12,14.4l-4.2-4.2l0.5-0.5L12,13.3z"></path>,
	height: 24,
	width: 24
});

IconArrowSMDown.displayName = 'IconArrowSMDown';

export default IconArrowSMDown;
