import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconFilterBlog = createIconComponent({
	content: (
		<fragment>
			<path d="M16,29.5 C8.54415588,29.5 2.5,23.4558441 2.5,16 C2.5,8.54415588 8.54415588,2.5 16,2.5 C23.4558441,2.5 29.5,8.54415588 29.5,16 C29.5,23.4558441 23.4558441,29.5 16,29.5 Z M16,28.5 C22.9035594,28.5 28.5,22.9035594 28.5,16 C28.5,9.09644063 22.9035594,3.5 16,3.5 C9.09644063,3.5 3.5,9.09644063 3.5,16 C3.5,22.9035594 9.09644063,28.5 16,28.5 Z"></path>
		</fragment>
	),
	height: 32,
	width: 32
});

IconFilterBlog.displayName = 'IconFilterBlog';

export default IconFilterBlog;
