import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'swiper';
import nanoid from 'nanoid';
import { get } from 'lodash';
import { Grid, Row, Cell } from '../Grid';
import { IconArrowLGLeft, IconArrowLGRight } from './../Icons';
import './HowToWatchCalendar.scss';

class HowToWatchCalendar extends React.Component {
	constructor(props) {
		super(props);

		this.swiper = undefined;
		this.carouselRef = React.createRef();
		this.arrowNextRef = React.createRef();
		this.arrowPrevRef = React.createRef();

		this.initSwiper = this.initSwiper.bind(this);
	}

	componentDidMount() {
		this.initSwiper();
	}

	componentWillUnmount() {
		if (this.swiper) {
			this.swiper.destroy();
		}
	}

	initSwiper() {
		// const { loop, keyboardEnabled, coverflow, slidesPerView, spaceBetween, centeredSlides } = this.props;
		const settings = {
			init: false,
			autoHeight: true,
			spaceBetween: 0,
			slidesPerView: 1,
			centeredSlides: true,
			grabCursor: true,
			preloadImages: true,
			updateOnImagesReady: true,
			navigation: {
				nextEl: this.arrowNextRef.current,
				prevEl: this.arrowPrevRef.current,
			},
			slideShadows: false
		};

		this.swiper = new Swiper(this.carouselRef.current, settings);
		this.swiper.on('slideChange', () => this.props.onChangeSlide(this.swiper.realIndex));
		this.swiper.init();

		window.addEventListener('load', () => this.swiper.update());
	}

	render() {
		const { heading, slides } = this.props;

		const slide = s => (
			<div key="{nanoid()}" className="swiper-slide">
				<div className="c-calendar-card__info">
					<h3 className="c-calendar-card__name">
						<span className="c-calendar-card__name-inner">{get(s, 'raceEvent.name')}</span>
					</h3>
					<span className="c-calendar-card__location">{get(s, 'raceEvent.locationName')}</span>
					<div className="c-calendar-card__dates">{s.dates}</div>
				</div>
				<figure className="c-how-to-watch-calendar-card__image">
					<img src="{get(s," 'raceEvent.eventIntroImage.file.url')}="" alt="Medios para eventos">
				</figure>
			</div>
		);

		return (
			<grid className="c-how-to-watch__calendar" noVerticalPadding="">
				<row>
					<cell columns="{12}">
						<div className="c-how-to-watch__title">{heading.toUpperCase()}</div>
					</cell>
				</row>
				<row>
					<cell columns="{12}">
						<div className="swiper-container" ref="{this.carouselRef}">
							<div className="c-carousel__wrapper swiper-wrapper">
								{ slides.map(slide) }
							</div>
							<div className="c-carousel__arrow c-carousel__arrow--prev swiper-button-prev" ref="{this.arrowPrevRef}">
								<iconarrowlgleft></iconarrowlgleft>
							</div>
							<div className="c-carousel__arrow c-carousel__arrow--next swiper-button-next" ref="{this.arrowNextRef}">
								<iconarrowlgright></iconarrowlgright>
							</div>
						</div>
					</cell>
				</row>
			</grid>
		);
	}
}

HowToWatchCalendar.propTypes = {
	heading: PropTypes.string,
	slides: PropTypes.arrayOf(
		PropTypes.shape({
			context: PropTypes.string,
			heading: PropTypes.string,
			subheading: PropTypes.string,
			image: PropTypes.string,
		})
	),
	onChangeSlide: PropTypes.func,
};

HowToWatchCalendar.defaultProps = {
	heading: '',
	slides: [],
	onChangeSlide: () => {},
};

export default HowToWatchCalendar;
