import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import './OnwardJourneyBlockItem.scss';
import { Img, AngledDivider } from '@sailgp/sailgp-shared-components';


const OnwardJourneyBlockItem = ({ title, ctaText, url, pageImage }) => (
	<link to="{url}" title="{title" ||="" ''}="" className="c-onward-journey-block-item">
		{
			pageImage &&
				<div className="c-onward-journey-block-item__image">
					<img src="{get(pageImage," 'file.url',="" '="" images="" generic-onward-journey.jpg')}="" imgWidth="{get(pageImage," 'file.details.image.width',="" 610)}="" imgHeight="{get(pageImage," 'file.details.image.height',="" undefined)}="" angledMask="{true}" angledMaskFillColor="#061a1b">
				</div>
		}
		<angleddivider angleFillColor="#fff" angleDirection="left" anglePosition="top" className="c-onward-journey-block-item__mask-top-angle"></angleddivider>
		<div className="c-onward-journey-block-item__copy">
			{ title && <h2 className="c-onward-journey-block-item__title">{title}</h2> }
			{ ctaText &&
				<div className="c-onward-journey-block-item__desc">
					{ctaText}
				</div>
			}
		</div>

		<angleddivider angleFillColor="#fff" angleDirection="right" anglePosition="bottom" className="c-onward-journey-block-item__mask-bottom-angle"></angleddivider>
	
);

OnwardJourneyBlockItem.propTypes = {
	title: PropTypes.string,
	ctaText: PropTypes.string,
	url: PropTypes.string,
	pageImage: PropTypes.object,
};

OnwardJourneyBlockItem.defaultProps = {
	title: undefined,
	ctaText: undefined,
	url: '#',
	pageImage: {},
};

export default OnwardJourneyBlockItem;
