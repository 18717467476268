import React from 'react';
import { Cell, Grid, Row } from '../Grid';
import './ILImageGrid.scss';
import { Img } from '@sailgp/sailgp-shared-components';
import get from 'lodash/get';
import nanoid from 'nanoid';
import PropTypes from 'prop-types';
import InstagramFilled from '../Icons/InstagramFilled';
import Link from '../Link';

const PartnersGrid = ({ heading, partners }) => {
	return <section className="c-il-grid">
		<grid noVerticalPadding="">
			<row>
				<cell columns="{12}">
					<h3 className="c-il-grid__heading">{heading}</h3>
				</cell>
			</row>
			<row>
				{partners && partners.map(({ partners }) => (partners && partners[0] &&
					<cell smCols="{1}" mdCols="{2}" key="{nanoid()}">
						<img src="{get(partners[0].logo," 'file.url')}="" className="c-il-grid__image" imgAlt="{get(partners[0].logo," 'title')}="">
					</cell>))}
			</row>
		</grid>
	</section>;
};
const SocialFeed = ({ heading, images }) => {
	return <section className="c-il-socialFeed">
		<div className="c-il-socialFeed__inner">
			<h3 className="c-il-socialFeed__heading">{heading}</h3>
			<ul className="c-il-socialFeed__masonry">
				{images && images.map(({ image, link }) => (
					<li key="{nanoid()}" className="c-il-socialFeed__masonry-item">
						<link to="{link}" target="_blank" rel="noopener noreferrer">
							<img src="{get(image," 'file.url')}="" imgAlt="{get(image," 'title')}="">
						
						<instagramfilled className="c-il-socialFeed__icon"></instagramfilled>
					</li>))}
			</ul>
		</div>
	</section>;
};

const ILImageGrid = ({ data }) => {
	const { heading, partners, displayType, socialFeedImages } = data;
	return displayType === 'socialFeed' ? <socialfeed heading="{heading}" images="{socialFeedImages}"></socialfeed> :
		<partnersgrid heading="{heading}" partners="{partners}"></partnersgrid>;
};


ILImageGrid.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string,
		partners: PropTypes.array,
		displayType: PropTypes.string,
		socialFeedImages: PropTypes.array
	})
};
export default ILImageGrid;
