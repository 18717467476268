import React, { useRef, useState } from 'react';
import {
	Accordion as AccordionWrapper,
	AccordionItem,
	AccordionItemButton,
	AccordionItemPanel,
	AccordionItemState
} from 'react-accessible-accordion';
import './ILLeaderboardAccordion.scss';
import { Img } from '@sailgp/sailgp-shared-components';
import IconPlus from '../../Icons/Plus';
import IconMinus from '../../Icons/Minus';
import ILLeaderboardCategories from '../ILLeaderboardCategories/ILLeaderboardCategories';
import { ScoreBreakdown } from '../Tile';
import { getPartnerData, getTeam } from '../utils/helpers';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import { useQuery } from '@tanstack/react-query';

const LeaderboardHead = () => {
	return <div className="c-il-leaderboard__header">
		<div className="c-il-leaderboard__header-empty"></div>
		<div className="c-il-leaderboard__header-content">
					<span className="c-il-leaderboard__header-position">
						<span className="c-il-leaderboard__show--desktop">Position</span>
						<span className="c-il-leaderboard__show--mobile">Pos</span>
					</span>
			<span className="c-il-leaderboard__header-flag c-il-leaderboard__show--desktop"></span>
			<span className="c-il-leaderboard__header-name">Team</span>
			<span className="c-il-leaderboard__header-partner c-il-leaderboard__show--desktop">Partner</span>
			<span className="c-il-leaderboard__header-points">
						<span className="c-il-leaderboard__show--desktop">Impact points</span>
						<span className="c-il-leaderboard__show--mobile">Points</span>
					</span>
		</div>
		<div className="c-il-leaderboard__header-button"></div>
	</div>;
};

const LeaderboardRow = (props) => {
	const [panelHeight, setPanelHeight] = useState(0);
	const panelRef = useRef(null);


	const onAccordionStateUpdate = ({ expanded }) => {
		if (panelRef.current) {
			setPanelHeight(expanded ? panelRef.current.scrollHeight : 0);
		}
		return <>
			<span className="c-il-leaderboard__row-button__text">See
				{expanded ? ' Less' : ' More'}
			</span>
			<span className="c-il-leaderboard__row-button__icon">
				{expanded ? <iconminus></iconminus> : <iconplus></iconplus>}
			</span>
		</>;
	};

	const { Position, TeamName, PointsAwarded, CategoryScores, CriteriaBreakdown, contentfulData } = props;

	const team = getTeam(contentfulData.listOfTeams, TeamName);
	let teamPartner;
	if(team) {
		teamPartner = getPartnerData(team);
	}


	return (<>
			{team && teamPartner ? <accordionitem>
				<div className="c-il-leaderboard__row">
					<div className="c-il-leaderboard__row-empty"></div>
					<div className="c-il-leaderboard__row-content">
						<span className="c-il-leaderboard__row-position">{Position}</span>
						<span className="c-il-leaderboard__row-flag c-il-leaderboard__show--desktop">
					<img src="{get(team.leaderboardFlag," 'file.url')}="" imgAlt="{get(team.leaderboardFlag," 'title')}="">
				</span>
						<h3 className="c-il-leaderboard__row-name">{team.name}</h3>
						<span className="c-il-leaderboard__row-partner c-il-leaderboard__show--desktop">
					<img src="{get(teamPartner.logo," 'file.url')}="" imgAlt="{get(teamPartner.logo," 'title')}="">
				</span>
						<span className="c-il-leaderboard__row-points"> {PointsAwarded}
							<span className="c-il-leaderboard__row-metric">pts</span>
				</span>
					</div>
					<accordionitembutton className="c-il-leaderboard__row-button">
						<accordionitemstate>
							{onAccordionStateUpdate}
						</accordionitemstate>
					</accordionitembutton>
				</div>

				<accordionitempanel className="c-il-leaderboard__panel">
					<div ref="{panelRef}" className="c-il-leaderboard__panel-content-wrapper" style="{{" height:="" panelHeight="" }}="">
						<div className="c-il-leaderboard__panel-content">
							<div className="c-il-leaderboard__panel-partner c-il-leaderboard__show--mobile">
								<img src="{get(teamPartner.logo," 'file.url')}="" imgAlt="{get(teamPartner.logo," 'title')}="">
							</div>
							{CategoryScores &&
								<illeaderboardcategories updateAcordionHeight="{setPanelHeight}" categories="{CategoryScores}" boat="{team.boatSchematicImage}"></illeaderboardcategories>
							}
							{CriteriaBreakdown &&
								<div className="c-il-leaderboard__categories">
									<img src="{get(team.boatSchematicImage," 'file.url')}="" imgAlt="{get(team.boatSchematicImage," 'title')}="" className="c-il-leaderboard__categories__ship-image c-il-leaderboard__show--desktop">
									<scorebreakdown showAlways="{true}" data="{{" ...props,="" CriteriaScores:="" CriteriaBreakdown="" }}=""></scorebreakdown>
								</div>
							}

						</div>
					</div>
				</accordionitempanel>
			</accordionitem> : null}
	</>
		);
};

const ILLeaderboardAccordion = (props) => {
	const { LeaderBoard, Teams } = props;
	const { data: contentfulData } = useQuery({ queryKey: ['ILLeaderboardContentful'] });

	return (<section>
		<accordionwrapper allowZeroExpanded="" className="c-il-leaderboard-accordions">
			{LeaderBoard &&
				<>
					<leaderboardhead></leaderboardhead>
					{LeaderBoard.map((leaderboardRow, index) =>
						<leaderboardrow {...{="" Position:="" index="" +="" 1,="" ...leaderboardRow,="" Teams,="" contentfulData="" }}="" key="{nanoid()}"></leaderboardrow>)}
				</>
			}
		</accordionwrapper>
	</section>);
};

ILLeaderboardAccordion.propTypes = {
	props: PropTypes.shape({
		data: PropTypes.object,
		Leaderboard: PropTypes.array
	})
};

export default ILLeaderboardAccordion;
