import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconGooglePlay = createIconComponent({
	content: (
		<fragment>
			<path d="M130,40H5c-2.8,0-5-2.2-5-5V5c0-2.8,2.2-5,5-5h125c2.8,0,5,2.2,5,5v30C135,37.7,132.8,40,130,40z"></path>
			<path fill="#a6a6a6" d="M130,0.8c2.3,0,4.2,1.9,4.2,4.2v30c0,2.3-1.9,4.2-4.2,4.2H5c-2.3,0-4.2-1.9-4.2-4.2V5c0-2.3,1.9-4.2,4.2-4.2
				H130 M130,0H5C2.2,0,0,2.2,0,5v30c0,2.8,2.2,5,5,5h125c2.8,0,5-2.2,5-5V5C135,2.3,132.8,0,130,0z"></path>
			<path fill="#fff" d="M47.4,10.2c0,0.8-0.2,1.5-0.7,2c-0.6,0.6-1.3,0.9-2.2,0.9s-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.2c0-0.9,0.3-1.6,0.9-2.2
				c0.6-0.6,1.3-0.9,2.2-0.9c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.4,0.9,0.7l-0.5,0.5c-0.4-0.5-0.9-0.7-1.6-0.7c-0.6,0-1.2,0.2-1.6,0.7
				c-0.5,0.4-0.7,1-0.7,1.7s0.2,1.3,0.7,1.7c0.5,0.4,1,0.7,1.6,0.7c0.7,0,1.2-0.2,1.7-0.7c0.3-0.3,0.5-0.7,0.5-1.2h-2.2V9.9h2.9
				C47.4,9.9,47.4,10.2,47.4,10.2z M52,7.7h-2.7v1.9h2.5v0.7h-2.5v1.9H52V13h-3.5V7H52V7.7z M55.3,13h-0.8V7.7h-1.7V7H57v0.7h-1.7V13z
				M59.9,13V7h0.8v6H59.9z M64.1,13h-0.8V7.7h-1.7V7h4.1v0.7H64L64.1,13L64.1,13z M73.6,12.2c-0.6,0.6-1.3,0.9-2.2,0.9
				c-0.9,0-1.6-0.3-2.2-0.9s-0.9-1.3-0.9-2.2s0.3-1.6,0.9-2.2s1.3-0.9,2.2-0.9c0.9,0,1.6,0.3,2.2,0.9s0.9,1.3,0.9,2.2
				C74.5,10.9,74.2,11.6,73.6,12.2z M69.8,11.7c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.7S73.5,8.7,73,8.3
				c-0.4-0.4-1-0.7-1.6-0.7s-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1-0.7,1.7S69.3,11.3,69.8,11.7z M75.6,13V7h0.9l2.9,4.7V7h0.8v6h-0.8
				l-3.1-4.9V13C76.3,13,75.6,13,75.6,13z"></path>
			<path fill="#fff" d="M68.1,21.8c-2.4,0-4.3,1.8-4.3,4.3c0,2.4,1.9,4.3,4.3,4.3s4.3-1.8,4.3-4.3C72.4,23.5,70.5,21.8,68.1,21.8z
				M68.1,28.6c-1.3,0-2.4-1.1-2.4-2.6s1.1-2.6,2.4-2.6s2.4,1,2.4,2.6C70.5,27.5,69.4,28.6,68.1,28.6z M58.8,21.8
				c-2.4,0-4.3,1.8-4.3,4.3c0,2.4,1.9,4.3,4.3,4.3s4.3-1.8,4.3-4.3C63.1,23.5,61.2,21.8,58.8,21.8z M58.8,28.6c-1.3,0-2.4-1.1-2.4-2.6
				s1.1-2.6,2.4-2.6s2.4,1,2.4,2.6C61.2,27.5,60.1,28.6,58.8,28.6z M47.7,23.1v1.8H52c-0.1,1-0.5,1.8-1,2.3c-0.6,0.6-1.6,1.3-3.3,1.3
				c-2.7,0-4.7-2.1-4.7-4.8s2.1-4.8,4.7-4.8c1.4,0,2.5,0.6,3.3,1.3l1.3-1.3c-1.1-1-2.5-1.8-4.5-1.8c-3.6,0-6.7,3-6.7,6.6
				s3.1,6.6,6.7,6.6c2,0,3.4-0.6,4.6-1.9c1.2-1.2,1.6-2.9,1.6-4.2c0-0.4,0-0.8-0.1-1.1C53.9,23.1,47.7,23.1,47.7,23.1z M93.1,24.5
				c-0.4-1-1.4-2.7-3.6-2.7s-4,1.7-4,4.3c0,2.4,1.8,4.3,4.2,4.3c1.9,0,3.1-1.2,3.5-1.9l-1.4-1c-0.5,0.7-1.1,1.2-2.1,1.2
				s-1.6-0.4-2.1-1.3l5.7-2.4C93.3,25,93.1,24.5,93.1,24.5z M87.3,25.9c0-1.6,1.3-2.5,2.2-2.5c0.7,0,1.4,0.4,1.6,0.9
				C91.1,24.3,87.3,25.9,87.3,25.9z M82.6,30h1.9V17.5h-1.9V30z M79.6,22.7c-0.5-0.5-1.3-1-2.3-1c-2.1,0-4.1,1.9-4.1,4.3
				s1.9,4.2,4.1,4.2c1,0,1.8-0.5,2.2-1h0.1v0.6c0,1.6-0.9,2.5-2.3,2.5c-1.1,0-1.9-0.8-2.1-1.5l-1.6,0.7c0.5,1.1,1.7,2.5,3.8,2.5
				c2.2,0,4-1.3,4-4.4V22h-1.8C79.6,22,79.6,22.7,79.6,22.7z M77.4,28.6c-1.3,0-2.4-1.1-2.4-2.6s1.1-2.6,2.4-2.6s2.3,1.1,2.3,2.6
				S78.7,28.6,77.4,28.6z M101.8,17.5h-4.5V30h1.9v-4.7h2.6c2.1,0,4.1-1.5,4.1-3.9S103.9,17.5,101.8,17.5z M101.9,23.5h-2.7v-4.3h2.7
				c1.4,0,2.2,1.2,2.2,2.1C104,22.4,103.2,23.5,101.9,23.5z M113.4,21.7c-1.4,0-2.8,0.6-3.3,1.9l1.7,0.7c0.4-0.7,1-0.9,1.7-0.9
				c1,0,1.9,0.6,2,1.6v0.1c-0.3-0.2-1.1-0.5-1.9-0.5c-1.8,0-3.6,1-3.6,2.8c0,1.7,1.5,2.8,3.1,2.8c1.3,0,1.9-0.6,2.4-1.2h0.1v1h1.8v-4.8
				C117.2,23,115.5,21.7,113.4,21.7z M113.2,28.6c-0.6,0-1.5-0.3-1.5-1.1c0-1,1.1-1.3,2-1.3c0.8,0,1.2,0.2,1.7,0.4
				C115.2,27.8,114.2,28.6,113.2,28.6z M123.7,22l-2.1,5.4h-0.1l-2.2-5.4h-2l3.3,7.6l-1.9,4.2h1.9l5.1-11.8H123.7z M106.9,30h1.9V17.5
				h-1.9C106.9,17.5,106.9,30,106.9,30z"></path>
			<g>
				<lineargradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="21.77" y1="574.68" x2="4.9876" y2="591.4624" gradientTransform="matrix(1 0 0 1 0 -566)">
					<stop offset="0" stopColor="#00a0ff"></stop>
					<stop offset=".00657445" stopColor="#00a1ff"></stop>
					<stop offset=".2601" stopColor="#00beff"></stop>
					<stop offset=".5122" stopColor="#00d2ff"></stop>
					<stop offset=".7604" stopColor="#00dfff"></stop>
					<stop offset="1" stopColor="#00e3ff"></stop>
				</lineargradient>
				<path fill="url(#SVGID_1_)" d="M10.4,7.5c-0.3,0.3-0.5,0.8-0.5,1.4V31c0,0.6,0.2,1.1,0.5,1.4l0.1,0.1l12.4-12.4v-0.2L10.4,7.5z"></path>
				<lineargradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="33.8344" y1="586.05" x2="9.6375" y2="586.05" gradientTransform="matrix(1 0 0 1 0 -566)">
					<stop offset="0" stopColor="#ffe000"></stop>
					<stop offset=".4087" stopColor="#ffbd00"></stop>
					<stop offset=".7754" stopColor="orange"></stop>
					<stop offset="1" stopColor="#ff9c00"></stop>
				</lineargradient>
				<path fill="url(#SVGID_2_)" d="M27,24.3l-4.1-4.1v-0.3l4.1-4.1l0.1,0.1l4.9,2.8c1.4,0.8,1.4,2.1,0,2.9L27,24.3z"></path>
				<lineargradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="24.8155" y1="588.2845" x2="2.0572" y2="611.0428" gradientTransform="matrix(1 0 0 1 0 -566)">
					<stop offset="0" stopColor="#ff3a44"></stop>
					<stop offset="1" stopColor="#c31162"></stop>
				</lineargradient>
				<path fill="url(#SVGID_3_)" d="M27.1,24.2L22.9,20L10.4,32.5c0.5,0.5,1.2,0.5,2.1,0.1L27.1,24.2"></path>
				<lineargradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="7.2617" y1="566.2118" x2="17.4243" y2="576.3743" gradientTransform="matrix(1 0 0 1 0 -566)">
					<stop offset="0" stopColor="#32a071"></stop>
					<stop offset=".0685" stopColor="#2da771"></stop>
					<stop offset=".4762" stopColor="#15cf74"></stop>
					<stop offset=".8009" stopColor="#06e775"></stop>
					<stop offset="1" stopColor="#00f076"></stop>
				</lineargradient>
				<path fill="url(#SVGID_4_)" d="M27.1,15.8L12.5,7.5c-0.9-0.5-1.6-0.4-2.1,0.1L22.9,20C22.9,20,27.1,15.8,27.1,15.8z"></path>
				<path d="M27,24.1l-14.5,8.2c-0.8,0.5-1.5,0.4-2,0l-0.1,0.1l0.1,0.1c0.5,0.4,1.2,0.5,2,0L27,24.1z" opacity=".2"></path>
				<path d="M10.4,32.3C10.1,32,10,31.5,10,30.9V31c0,0.6,0.2,1.1,0.5,1.4v-0.1H10.4z M32,21.3l-5,2.8l0.1,0.1L32,21.3
					c0.7-0.3,1-0.8,1-1.3C33,20.5,32.6,20.9,32,21.3z"></path>
				<path fill="#fff" d="M12.5,7.6L32,18.7c0.6,0.4,1,0.8,1,1.3c0-0.5-0.3-1-1-1.4L12.5,7.5C11.1,6.7,10,7.4,10,9v0.1
					C10,7.5,11.1,6.8,12.5,7.6z"></path>
			</g>
		</fragment>
	),
	height: 40,
	width: 135
});

IconGooglePlay.displayName = 'IconGooglePlay';

export default IconGooglePlay;
