import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';
import './Medals.scss';

const IconGoldMedal = createIconComponent({
	content: (
		<fragment>
			<rect style="{{fill:" 'none'}}="" width="32" height="32"></rect>
			<circle className="c-medal-gold" cx="16" cy="16" r="13"></circle>
			<g style="{{enableBackground:" 'new'}}="">
				<path d="M18.1,14.7c0-1.3-0.7-2.4-2.2-2.4c-1.9,0-3.1,1.9-3.1,4c0,1.7,0.9,2.7,2.3,2.7c1.3,0,2.2-0.8,2.7-2h-2.3l0.3-1.4h4
					l-1.1,4.9h-1.5l0.3-1.3c-0.6,1-1.5,1.5-2.8,1.5c-2.2,0-3.7-1.7-3.7-4.2c0-3.2,2.1-5.8,5.1-5.8c2.5,0,3.7,1.7,3.7,4L18.1,14.7z"></path>
			</g>
		</fragment>
	),
	height: 32,
	width: 32
});

IconGoldMedal.displayName = 'IconGoldMedal';

export default IconGoldMedal;
