import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';
import './Medals.scss';

const IconBronzeMedal = createIconComponent({
	content: (
		<fragment>
			<rect style="{{fill:" 'none'}}="" width="32" height="32"></rect>
			<circle className="c-medal-bronze" cx="16" cy="16" r="13"></circle>
			<g style="{{enableBackground:" 'new'}}="">
				<path style="{{fill:" '#ffffff'}}="" d="M11.2,20.4l2.2-8.8h3.9c1.7,0,2.6,0.7,2.6,2s-1,2.1-2.1,2.3c0.8,0.2,1.5,0.8,1.5,1.7c0,1.9-1.8,2.8-3.8,2.8
					H11.2z M13.8,16.6L13.2,19h2.2c1.3,0,2-0.6,2-1.5c0-0.7-0.5-1-1.3-1H13.8z M14.1,15.3h2c1.3,0,1.9-0.5,1.9-1.3
					c0-0.6-0.4-0.9-1.2-0.9h-2.1L14.1,15.3z"></path>
			</g>
		</fragment>
	),
	height: 32,
	width: 32
});

IconBronzeMedal.displayName = 'IconBronzeMedal';

export default IconBronzeMedal;
