import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconStandings = createIconComponent({
	content: (
		<fragment>
			<path d="M1.231 25.846v4.923h4.923v-4.923h-4.923zM7.385 24.615v7.385h-7.385v-7.385h7.385zM12.308 18.462v12.308h4.923v-12.308h-4.923zM18.462 17.231v14.769h-7.385v-14.769h7.385zM23.385 22.154v8.615h4.923v-8.615h-4.923zM29.538 20.923v11.077h-7.385v-11.077h7.385zM14.769 10.997l2.8 1.472-0.535-3.118 2.265-2.208-3.13-0.455-1.4-2.837-1.4 2.837-3.13 0.455 2.265 2.208-0.535 3.118 2.8-1.472zM14.769 12.388l-4.434 2.331 0.847-4.938-3.588-3.497 4.958-0.72 2.217-4.493 2.217 4.493 4.958 0.72-3.588 3.497 0.847 4.938-4.434-2.331z"></path>
		</fragment>
	),
	height: 32,
	width: 32
});

IconStandings.displayName = 'IconStandings';

export default IconStandings;
